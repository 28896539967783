import { inView } from 'motion'

export function useCanSee(targetRef: Ref<null | HTMLElement>, canSee?: Function, onLeave?: Function) {
  onMounted(() => {
    if (targetRef.value) {
      inView(targetRef.value, (info) => {
        if (canSee)
          canSee(true, info)

        return (leaveInfo) => {
          if (onLeave)
            onLeave(false, leaveInfo)
        }
      })
    }
  })
}
